#ChatBotContainer {
  position: fixed;
  bottom: 20px;
  right: 10px;
  /* border: 2px solid red; */
}


.container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  /* width: 720px; */
  margin: 20px;
  box-shadow: 0 4px 15px 5px #0000001a;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  background-color: white;
  border-radius: 25px;
}

#output-container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  min-height: 60vh;
  /* border: 2px solid red; */

}







.messages-container {
  /* width: 70%; */

  width: 55%;

}

.message-item {
  display: grid;
  grid-template-rows: auto auto;
  /* Two rows */
  grid-template-columns: 1fr 1fr;
  /* Two columns, both full width */

  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  /* Optional: Add border for clarity */
}

.success-status {
  grid-row: 1;
  /* First row */
  grid-column: 1;
  /* First column */
  font-size: 14px;
  /* color: green; */
  /* Customize color as needed */
  /* background-color: #f1f1f1; */
  border-radius: 5px;

  background-color: var(--intercom-operator-message-bg);
  padding: 15px;
  margin: 10px 0px;
  width: fit-content;

  font-family: sans-serif;
}

.message p {
  line-height: 1.8 !important;
}

.message {
  grid-row: 2;
  /* Second row */
  grid-column: 2;
  /* Second column */
  font-size: 15px;
  color: #333;
  /* Customize color as needed */
  /* Align text to the right */
  background-color: #f1f1f1;
  border-radius: 5px;

  background-color: #c5c5ff;
  /* width: fit-content; */
  margin-left: 40px;
  padding: 11px;
  /* color: white; */
  margin-right: 5px;
  width: 65%;
  font-family: sans-serif;
}

.hello-container div video,
.hello-container video {
  border: 1px solid Zz !important;
  border: 1px solid Zz !important;
}

.hello-container div video {
  /* max-height: 50vh; */
  max-height: 60vh;
}

/* .hello-container .staticVideoClass video , .staticVideoClass video{
  object-fit: cover;
} */


/* .hello-container .sharedVideoClass , .hello-container .staticVideoClass .sharedVideoClass{
  object-fit: cover !important; 
} */





.hello-container {
  /* width: 45%; */
  /* min-height: 36vh; */
  text-align: right;
  font-size: 18px;
  font-weight: bold;

  color: #007bff;

  display: flex;
  justify-content: center;
  /* align-items: center; */

  /* height: fit-content; */
  /* height: 60vh; */
  /* padding: 50px; */
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px;
  border-top: 1px solid lightgrey;
}

input[type="text"] {
  flex: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  border: none;
  border-radius: 0 5px 5px 0;
  color: white;
  cursor: pointer;
}

button i {
  font-size: 16px;
}





/* recording send in api start */

/* Ensure these styles are added or updated in frameNew.css */
/* Ensure these styles are added or updated in frameNew.css */
.message-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 100%;
  border: none;
  /* Ensure the message item does not exceed the container width */
}

.message {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  /* Wrap long words to the next line */
  overflow-wrap: break-word;
  /* Ensure long words break and wrap within the container */
  max-width: 100%;
  /* Ensure the message container does not exceed its parent width */
}

audio {
  margin-top: 5px;
  width: 100%;
}

/* Optional: Style the message container */
.messages-container {
  max-width: 600px;
  /* Adjust width as needed */
  overflow: auto;
  max-height: 60vh;
  /* Add scroll if needed */
}

.input-container input {
  border: none;
  outline: none;
}

.input-container input {
  border: none;
  outline: none;
}

.input-container textarea {
  border: none;
  outline: none;
  width: 90%;
  font-family: sans-serif;
  height: 200px;
}

.input-container button {
  margin-left: 10px;
}

.recording-indicator {
  display: flex;
  align-items: center;
  position: relative;
  /* margin-right: 10px; */
}

.recording-line {
  width: 100%;
  height: 4px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.timer {
  margin-left: 8px;
  font-weight: bold;
  margin-bottom: 15px;
}

.recording-indicator button {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  margin-left: 10px;
}

.recording-indicator button i {
  margin-right: 5px;
}




/* .message audio {
align-self: flex-end;
width: 50%;
} */



/* css as per figma start */

.chatHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7ead18;
  padding: 18px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  color: white;
  flex-wrap: wrap;
}

.iconHeadChat img,
.headMinClose img {
  margin-right: 12px;
  max-height: 35px;
}

.headMinClose {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}


.iconHeadChat {
  display: flex;
  align-items: center;
}

.headTextClass .miniHead {
  font-size: 14px;
  margin-bottom: 6px;
}

.headTextClass {
  font-family: Arial, Helvetica, sans-serif;
}

.iconButton,
.headMinClose img {
  cursor: pointer;
  margin-right: 10px;
  height: 22px;
}

/* .iconButton img {
  height: 28px;
} */

.receiverIconText,
.senderIconText {
  display: flex;
  align-items: center;
}

.senderIconText {
  justify-content: flex-end;
}

.senderIconText img,
.receiverIconText img {
  padding: 7px;
  max-height:35px ;
}

.helpfulText img {
  cursor: pointer;
  /* height: 15px; */
}

.helpfulText .thumbsDownClass {
  margin-top: 7px;
}

.helpfulText {
  /* border-bottom: 1px solid lightgrey; */
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}





.hoverZoom {

  transition: transform .2s;
  margin-left: 5px;
}

.hoverZoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}





/* css as per figma end */



/* .staticVideoClass {
  height: 0vh;
} */



.container {
  transition: transform 1s ease, opacity 1s ease;
}

.container.slide-down {
  transform: translateY(calc(100vh - 60px));
  opacity: 1;
}

.chatHead {
  transition: transform 1s ease;
  bottom: 0;
  width: 100%;
}

.container.slide-down .chatHead {
  transform: translateY(0);
}






/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Internet Explorer 10+ */
* {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge (legacy) */
}






/* dharmesh css */

/* .container{
position: fixed;
  right: 0;
} */


.videochartvalue {
  /* border: 1px solid #9ec5e5; */
  border-radius: 11px 11px 0px 0px;
  bottom: -59px;

  /* height: 355px; */
  /* transition: height 3s; */

  display: flex;
  justify-content: flex-end;
  /* height: fit-content; */
  /* transition: height 0.5s ease, padding 0.5s ease;  */

  transition: height 0.5s ease, width 0.5s ease;
}

.newvideochartvalue {
  border-radius: 11px 11px 0px 0px;
  bottom: 0;
  height: 0;
  /* width: 400px; */
  position: fixed;
  bottom: 100px;
  right: 15px;
  transition: height 3s;
}




/* css for api response in chat start  */


.receiver .senderIconText .message {
  margin: 0;
  background-color: #f2f2f2;
  line-height: 1.6 !important;
  font-family: Arial, Helvetica, sans-serif;
}

.receiver .senderIconText {
  flex-direction: row-reverse;
}

/* css for api response in chat end */




.chatBoxImageClass img {
  cursor: pointer;
  bottom: 25px;
  right: 40px;
  /* left: 950px; */
  max-height: 200px;
  position: absolute;
  /* box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); */
  border-radius: 3px;
  /* animation: Floating 3s ease-in-out infinite; */
}

@keyframes Floating {
  0% {
    transform: translate(0, 0);
  }

  65% {
    transform: translate(0, 50px);
  }

  100% {
    transform: translate(0, 0);
  }
}







/* CSS for mobile view */





@media (min-width: 769px) and (max-width: 1220px) {

  .chatBoxImageClass img {
bottom: 74px;
max-height: 175px;
  }

}




@media (max-width: 768px) {
  #output-container {
    flex-direction: column-reverse;
    align-items: center;
    /* min-height: 45vh; */
    /* min-height: 64vh; */
    /* min-height: 80vh; */
  }

  .hello-container {
    /* padding: 21px; */
    padding: 5px;
    /* height: 28vh; */
    max-height: 36vh;
    /* border: 2px solid red; */
  }

  .messages-container {
    /* max-height: 25vh; */
    min-height: 32vh;
    /* height: calc(100vh - 450px); */
    width: 95%;
  }

  .container {
    min-height: 66vh !important;
  }

  .headMinClose {
    width: 100%;
  }

  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }

}

@media (min-width: 446px) and (max-width: 768px) {

  .hello-container div video,
  .hello-container video {
    max-height: 25vh;
    /* border: 2px solid green; */
  }
  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }

}

@media (max-width: 400px) {
  .container {
    min-height: 71vh !important;
  }


  .headMinClose {
    /* width: 100%; */
  }
  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }
}



/* css for chat loading start */


.chat-bubble {
  /*   background-color:#E6F8F1; */
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6CAD96;
  }

  28% {
    transform: translateY(-7px);
    background-color: #9ECAB9;
  }

  44% {
    transform: translateY(0px);
    background-color: #B5D9CB;
  }
}



/* css for chat loading end */











.chat-bubble-audio {
  position: relative;
  padding: 5px;
  border-radius: 10px;
  max-width: 300px;
  /* margin: 10px 0; */
}


.chat-bubble {
  position: relative;
  padding: 5px;
  background-color: #f1f0f0;
  border-radius: 10px;
  max-width: 300px;
  /* margin: 10px 0; */
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: gray;
  border-radius: 50%;
  animation: typing 1s infinite ease-in-out;
}

@keyframes typing {

  0%,
  100% {
    opacity: 0.2;
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
}



/* css for loader before video start  */

.loaderVideo {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #7ead18;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* css for loader before video end  */


.loaderVideoOutside {
  height: 50vh;
  background-color: content-box;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
}


/* 
.container{
min-height: 82vh;
} */



/* ********************************************** */

.videochartvalue {
  bottom: -25px;
}


.slideChatBox {
  position: relative;
  opacity: 0;
  animation: slide-up 2s ease-in-out forwards;
}

/* Keyframes for the sliding up animation */
@keyframes slide-up {
  0% {
    transform: translateY(800px);
    /* Start from 100px below */
    opacity: 1;
  }


  100% {
    transform: translateY(100px);
    /* End at the original position */
    opacity: 1;
  }
}





/* ********************************** */


/* language css start */


.languagesDiv .arrow .downArrowClass {
  /* margin: 0; */
  margin-top: 4px;
  height: 8px;
  margin-right: 0;
}

.languagesDiv .languageDropdown .searchLanguageInput{
  
  outline: none;
  border: none;
}


.languagesDiv {
  position: relative;
  cursor: pointer;
  /* padding: 3px 6px; */
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  display: inline-block;
  /* background-color: #f8f8f8; */
  display: flex;
  justify-content: space-between;
  /* To align text and arrow */
  align-items: center;
  min-width: 110px;
  /* Adjust the width as needed */
  font-family: Arial, Helvetica, sans-serif;

  margin-right: 10px;
  margin-top: 6px;
}

.arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
  /* Rotate the arrow when dropdown is open */
}

.languageDropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  position: absolute;
  top: 100%;
  /* Position it below the 'languagesDiv' */
  left: 0;
  z-index: 1000;
  width: 100%;
  /* Make it as wide as the 'languagesDiv' */
  max-height: 150px;
  overflow: scroll;
  overflow-x: hidden;
}

.languageDropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

.languageDropdown li:hover {
  background-color: #f0f0f0;
}


/* Show scrollbar for 'languageDropdown' */

/* .languageDropdown {
  scrollbar-width: initial;
  -ms-overflow-style: auto;
}

.languageDropdown::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.languageDropdown::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
} */


/* language css end */






/* css for mobile height  */


/* ************************************ */
/* Default styles for small devices */
body,
html {
  height: 100vh;
  /* Full viewport height */
}

/* Devices with a max-width of 576px (small phones) */
@media (max-width: 576px) {
  .container {
    /* height: 80vh; */
    /* Adjust height for smaller screens */
    display: flex;
    justify-content: space-between;

    /* width: calc(100% - 40px); */
    margin: 0;
  }


  .headMinClose {
    width: 100%;
  }

  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }
}

/* Devices with a max-width of 768px (medium-sized phones) */
@media (max-width: 768px) {
  .container {
    /* height: 85vh; */
    /* Adjust height for medium-sized screens */

    /* width: calc(100% - 40px); */
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .headMinClose {
    margin-top: 5px;
    width: 100%;
  }

  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }
}

/* Devices with a max-width of 992px (large phones) */
@media (max-width: 992px) {
  .container {
    /* height: 90vh; */
    /* new changes 25sep */
    /* height: 100vh; */
    border-radius: 0;

    /* Adjust height for large phones */

    /* width: calc(100% - 40px); */
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .chatHead {
    border-radius: 0;
  }

  .headMinClose {
    width: 100%;
  }

  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }
}

/* Devices with a max-width of 1200px (extra-large phones or small tablets) */
@media (max-width: 1200px) {
  .container {
    /* new changes 25sep */
    /* max-height: 95vh; */
    /* Adjust height for small tablets */

    /* width: calc(100% - 40px); */
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .headMinClose {
    /* width: 100%; */
  }

  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }
}



.avatarNameImgClass {
  display: flex;
  /* flex-direction: column;
justify-content: center; */
  align-items: center;
}

.speakerIconButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 65px;
  margin-left: 20px;
}

.speakerIconButton img {
  margin-right: 5px;
  margin-top: 5px;
}

.languagesDivBoth {
  display: flex;
  align-items: center;
}



.recording-indicator img {
  height: 18px;
}


.errorPageMainClassOutside{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorPageMainClass{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    font-family: sans-serif;
}


.errorHead{
font-size: 90px;
font-weight: bold;
color: red;
}

.errorOopsPara span{

  font-size: 50px;
}
.errorOopsPara{
  font-size: 30px;
}

.goBackButton{
  /* border: 1px solid blue; */
  color: white;
  background-color: #acaced;
  height: 35px;
  width: 150px;
  padding: 9px;
  border-radius: 8px;
  margin-top: 20px;
}



.languageSearchDropdown{
  margin-top: 35px;
}


.chat-container {
  max-height: 500px;  /* or whatever height you want */
  overflow-y: auto;   /* ensure scrolling is enabled */
}



/* media query for tester screen  */


/* @media screen and (min-width: 1366px) and (max-width: 1440px) {  
  .output-container {
      height: 100vh;
  }  
}

@media screen and (min-width: 1920px) and (max-width: 2000px) {  
  .output-container {
    height: 100vh;
}  
}

@media screen and (min-width: 1600px) and (max-width: 1680px) {  
  .output-container {
    height: 100vh;
}  
}

@media screen and (min-width: 1920px) and (max-width: 2000px) {  
  .output-container {
    height: 100vh;
}  
} */


/* @media (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
  .container{
    justify-content: flex-start !important;
  }
} */



/* user bot css starts  */

.response-options button{
  color: #7ead18;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #7ead18;
 margin: 5px;
}

.response-options button:hover {
  background-color: #7ead18;
  color: white;
  transition: 0.3s;
}

.current-question{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;

  /* background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: 1 / 1;
  width: fit-content;
  margin: 10px 0;
  padding: 15px; */
}

 .chat-history div .botParaClass {
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: 1 / 1;
  width: fit-content;
  margin: 10px 0;
  padding: 15px;
  width: 65%;
  line-height: 1.3;
}


.botMsgClass img ,.currentBotMsgClass img , .userMsgClass img{
  margin: 0px 8px;
}

.currentBotMsgClass img , .botMsgClass img{
  max-height: 25px;
}

.botMsgClass{
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.response-options{
  margin-left: 40px;
}

.userMsgClass .userParaClass{
    display: flex;
    align-items: center;
    background-color: #c5c5ff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    width: 60%;
   
}

.userMsgClass{
  align-items: center;
  display: flex;
  justify-content: end;
  width: 85%;
  margin-left: 25px;
}

.userParaClass{
  border-radius: 5px;
  grid-area: 1 / 1;
  width: fit-content;
  /* margin: 10px 0; */
  padding: 13px;
}

.currentBotMsgClass{
  display: flex;
  align-items: center;
}

.currentBotParaClass{
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: 1 / 1;
  width: 65%;
  margin: 10px 0;
  padding: 13px;
  line-height: 1.3;
}

.chatMainClass {
  height: 60vh;
  /* height: 30vh; */
  overflow: scroll;
  width: 80%;
}

.staticVideoClass{
  text-align: center;

}



@media (max-width: 768px) {
.chatMainClass{
  /* height: 37vh; */
  height: 53vh;
  /* border: 2px solid red; */
}

.hello-container{
  width: 40%;
}
}

.inputErrorClass{
  color: red;
  margin-left: 7px;
}



.chatBoxImageClass img {
  bottom: 74px;
  max-height: 175px;
    }
/* #output-container{
  min-height: 70vh;
  width: 40%;
} */

/* user bot css ends */

@media only screen and (width: 275px) and (height: 374px) and (-webkit-device-pixel-ratio: 2) {
  #ChatBotContainer{
    width: 90%;
    height: 90vh;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }

  
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
      }
}


@media only screen and (width: 375px) and (height: 667px) and (-webkit-device-pixel-ratio: 2) {
  #ChatBotContainer{
    width: 90%;
    height: 90vh;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }
}

/* @media only screen and (width: 414px) and (height: 896px) and (-webkit-device-pixel-ratio: 3) {
    
} */

@media screen and (min-width: 200px) and (max-width: 500px) {
  .headMinClose {
    width: 100%;
  }
}


@media screen and (min-width: 200px) and (max-width: 375px) {
  
  .headMinClose {
    width: 100%;
    /* border: 2px solid red; */
  }
}

@media screen and (min-width: 200px) and (max-width: 600px) {
  #ChatBotContainer{
    bottom: 0;
    right: 0;
    width: 100%;
    height: 94vh;
  }

  .chatMainClass{
    width: 98%;
  }

  .input-container input[type="text"] { 
    padding: 16px;
  }

  .userMsgClass{
    width: 92%;
  }

  /* .hello-container div video {
    max-height: 40vh;
  } */
}

@media screen and (min-width: 301px) and (max-width: 600px) {
  .hello-container div video {
    max-height: 26vh;
  }
  .chatHead {
    /* padding: 8px; */
    padding: 25px 8px;
  }

  .languagesDiv {
    min-width: 90px;
    font-size: 0.75rem;
  }

  /* .currentBotParaClass {
    font-size: 0.75rem;
  } */

  .response-options {
    font-size: 0.75rem;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }

  .speakerIconButton {
    min-width: 10px;
    margin-left: 4px;
  }

  .input-container {
    padding: initial;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  #ChatBotContainer{
    bottom: 0;
    right: 0;
    width: 100%;
    height: 90vh;
  }
  .chatMainClass{
    width: 95%;
    
  }
  .userMsgClass{
    width: 93%;
  }
  .headMinClose {
    width: initial;
    /* border: 2px solid red; */
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  #ChatBotContainer{
    width: 75%;
  }
  .headMinClose {
    width: initial;
    /* border: 2px solid red; */
  }

  #output-container {
    max-height: 70vh;
}

  /* .hello-container div video {
    max-height: 50vh;
  } */
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  #ChatBotContainer{
    width: 80%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {
  #ChatBotContainer{
    width: 80%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
  #ChatBotContainer{
    width: 70%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  

  /* .hello-container div video, .hello-container video {
    max-height: 50vh;
  } */
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  #ChatBotContainer{
    width: 70%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
  #ChatBotContainer{
    width: 47%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
  #ChatBotContainer{
    width: 50%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1600px) {
  #ChatBotContainer{
    width: 50%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  #ChatBotContainer{
    width: 45%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  #ChatBotContainer{
    width: 45%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1900px) {
  #ChatBotContainer{
    width: 40%;
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1901px) and (max-width: 2000px) {
  #ChatBotContainer{
    width: 40%;
    
  }
  .hello-container div video, .hello-container video {
    max-height: 61vh;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2100px) {
  #ChatBotContainer{
    width: 40%;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2200px) {
  #ChatBotContainer{
    width: 35%;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2300px) {
  #ChatBotContainer{
    width: 35%;
  }
}


@media screen and (min-width: 2301px) and (max-width: 2400px) {
  #ChatBotContainer{
    width: 30%;
  }
}

@media screen and (min-width: 2401px) and (max-width: 4000px) {
  #ChatBotContainer{
    width: 30%;
  }
}
