.App {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .chat-window {
    border: 1px solid #ccc;
    height: 300px;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .message.bot {
    color: #007bff;
  }
  
  .message.user {
    color: #28a745;
    text-align: right;
  }
  
  .message-text {
    white-space: pre-line; /* Ensures line breaks are displayed */
  }
  
  .input-container {
    display: flex;
  }
  
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  